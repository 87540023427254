<template>
  <b-row
    id="nacessary"
    class="d-flex flex-row justify-content-center mt-2 shadow-lg rounded-lg"
  >
    <b-col v-if="$route.params.id">
      <b-form ref="form">
        <b-row class="rounded-lg p-2 shadow-lg">
          <b-col>
            <b-row class="d-flex justify-content-end">
              <b-col
                class="d-flex flex-column justify-content-center align-items-center"
              >
                <img
                  src="@/assets/images/icons/profile-necessary-docs.png"
                  alt=""
                />
                <p class="keen-text text-center py-1">Necessary Docs</p>
              </b-col>

              <b-col cols="12" md="10">
                <b-row class="rounded-lg">
                  <b-col class="text-left py-1">
                    <h3>Passport Details</h3>
                    <hr />
                  </b-col>
                </b-row>
                <validation-observer ref="passportRules">
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label-for="Issued Country" class="data-text"
                        ><label for="Passport_Number"
                          >Issued Country
                          <span class="text-danger">*</span></label
                        >
                        <validation-provider
                          #default="{ errors }"
                          name="Issued Country"
                          rules="required"
                        >
                          <v-select
                            id="nationality"
                            v-model="SelectedCountry"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="countries"
                            placeholder="Select Country"
                            class="data-text bg-light"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group
                        ><label for="Passport_Number"
                          >Passport Number
                          <span class="text-danger">*</span></label
                        >
                        <validation-provider
                          #default="{ errors }"
                          name="Passport Number"
                          rules="required"
                        >
                          <b-form-input
                            :state="passportState"
                            id="Passport_Number"
                            v-model="passport.passport_number"
                            placeholder="Enter Your Passport Number"
                            class="data-text bg-light"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <label for=""
                          >Passport Expiry Date
                          <span class="text-danger">*</span></label
                        >
                        <validation-provider
                          #default="{ errors }"
                          name="Passport Expiry Date"
                          rules="required"
                        >
                          <flat-pickr
                            :state="passportValidationState"
                            v-model="passport.passport_validation"
                            required
                            placeholder="Passport Expiary Date"
                            class="data-text bg-light flat-pickar"
                            :config="{
                              altInput: true,
                              altFormat: 'F j, Y',
                              dateFormat: 'Y-m-d',
                            }"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="Upload Passport"
                        label-for="Upload-Passport"
                      >
                        <label
                          for="Upload-Passport"
                          class="bg-light custom-file-upload d-flex justify-content-between align-items-center"
                        >
                          <div class="d-flex align-items-center">
                            <img
                              src="@/assets/images/icons/paper-clip.png"
                              width="20"
                              alt=""
                            />
                            <span
                              v-if="passport.passport_file"
                              class="pl-1 file-name"
                              >{{ passport.passport_file }}</span
                            >
                            <span class="placeholder pl-1" v-else
                              >Please Passport File</span
                            >
                            <b-form-file
                              id="Upload-Passport"
                              class="bg-white d-none"
                              @change="handlePassportFile($event)"
                            />
                          </div>
                          <img
                            v-if="
                              passport.passport_file &&
                              passport.passport_file.includes('uploads/')
                            "
                            v-b-modal.modal-center
                            @click.prevent="
                              getPreviewFile(passport.passport_file)
                            "
                            src="@/assets/images/icons/eye.png"
                            alt=""
                          />
                        </label>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="text-right">
                      <button
                        v-if="
                          $route.params.id > 0 && $can('update', 'Employee')
                        "
                        class="btn update-btn"
                        @click.prevent="handlePassport"
                      >
                        Update Passport
                      </button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-col>
              <b-col cols="12" md="10" v-if="residentType == 0">
                <b-row class="rounded-lg">
                  <b-col class="text-left py-1">
                    <h3>Visa Details</h3>
                    <hr />
                  </b-col>
                </b-row>
                <validation-observer ref="visaRules">
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group invalid-feedback="Visa Is Required"
                        ><label for="visa_type">Visa Type</label>
                        <v-select
                          id="visa_type"
                          v-model="SelectedVisa"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="visa"
                          placeholder="Select Visa"
                          class="data-text bg-light"
                          @input="getSelectedVisaInfo"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        invalid-feedback="Visa Sub Class Is Required"
                        ><label for="sub_class">Visa Sub Class </label>
                        <v-select
                          id="sub_class"
                          v-model="SelectedVisaSubClass"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="visaSubClass"
                          placeholder="Select Visa"
                          class="data-text bg-light"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" md="6">
                      <b-form-group
                        ><label for="visa_grant_number"
                          >Visa Grant Number
                          <span class="text-danger">*</span></label
                        >
                        <validation-provider
                          #default="{ errors }"
                          name="Visa Grant Number"
                          rules="required"
                        >
                          <b-form-input
                            v-model="visaInfo.visa_grant_number"
                            placeholder="Enter Your Visa Grant Number"
                            required
                            class="data-text bg-light"
                            id="visa_grant_number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group

                      >
                        <label for=""
                          >Visa Expiry Date
                          <span class="text-danger">*</span></label
                        >
                        <validation-provider
                          #default="{ errors }"
                          name="Visa Expiry Date"
                          rules="required"
                        >
                          <flat-pickr
                            v-model="visaInfo.expiry_date"
                            required
                            placeholder="Visa Expiry Date"
                            class="data-text bg-light flat-pickar"
                            :config="{
                              altInput: true,
                              altFormat: 'F j, Y',
                              dateFormat: 'Y-m-d',
                            }"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="text-right">
                      <button
                        v-if="
                          $route.params.id > 0 && $can('update', 'Employee')
                        "
                        class="btn update-btn"
                        @click.prevent="handleVisa"
                      >
                        Update Visa
                      </button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-col>

              <b-col cols="12" md="10">
                <b-row class="rounded-lg">
                  <b-col class="text-left py-1">
                    <h3>Tax Details</h3>
                    <hr />
                  </b-col>
                </b-row>
                <validation-observer ref="taxRules">
                <b-row>
                  <b-col cols="12" md="5">
                    <b-form-group
                      invalid-feedback="TFN Number Or TFN Exemption Is Required"
                      :state="TFN_Number_Validation"
                      ><label for="tfn_number"
                        >Tax File Number
                        <span class="text-danger">*</span></label
                      >
                      <b-form-input
                        v-model="tax.tfn_number"
                        placeholder="Enter Your Tax File Number Number (TFN)"
                        :state="TFN_Number_Validation"
                        required
                        class="data-text bg-light"
                        @keyup="clearExemptionValue"
                        id="tfn_number"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="2" class="text-center">
                    <b-form-group>
                      <label for="" class="pb-2"></label>
                      <div>
                        <h3 class="py-1">OR</h3>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="5">
                    <b-form-group
                      invalid-feedback="TFN Number Or TFN Exemption Is Required"
                      :state="TFN_Exemption_Validation"
                      ><label for="">TFN Exemption </label>
                      <v-select
                        v-model="tax.tfn_exemption"
                        :state="TFN_Exemption_Validation"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :reduce="(exemption) => exemption.name"
                        :options="exemption"
                        placeholder="Select TFN Exemption Type"
                        class="data-text bg-light"
                        @input="clearNumberValue"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group
                      ><label for="">Tax Free Thrashold </label>
                      <b-form-checkbox
                        :disabled="tax.tfn_exemption == 'TFN not quoted'"
                        v-model="tax.free_tax_threshold"
                        name="checkbox-1"
                        value="1"
                        unchecked-value="0"
                      >
                        Claimed Tax Free Thrashold
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group
                      ><label for="">Tax Scale Type </label>
                      <v-select
                        :disabled="tax.tfn_exemption == 'TFN not quoted'"
                        v-model="tax.tax_scale_type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="taxScale"
                        :reduce="(taxScale) => taxScale.name"
                        placeholder="Select Tax Scale Type"
                        class="data-text bg-light"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-right">
                    <button
                      v-if="$route.params.id > 0 && $can('update', 'Employee')"
                      class="btn update-btn"
                      @click.prevent="updateTaxInformation"
                    >
                      Update Tax Info
                    </button>
                  </b-col>
                </b-row>
              </validation-observer>
              </b-col>
            </b-row>

            <b-row class="d-flex justify-content-end">
              <b-col cols="12" md="10">
                <b-row class="rounded-lg">
                  <b-col class="text-left py-1">
                    <h3>Driving License</h3>
                    <hr />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group label="Issued State" label-for="Issued State">
                      <b-form-input
                        v-model="driving.issuing_state"
                        placeholder="Enter Licence Issued State"
                        required
                        class="data-text bg-light"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group
                      invalid-feedback="Licence Number Is Requird"
                      :state="licenceState"
                    >
                      <label for=""
                        >Licence Number
                        <span class="text-danger">*</span></label
                      >
                      <b-form-input
                        @keyup="checkLicenceFormValidity"
                        :state="licenceState"
                        id=""
                        v-model="driving.license_number"
                        placeholder="Enter Your Licence Number"
                        class="data-text bg-light"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group
                      invalid-feedback="licence Validation Is Required"
                      :state="licenceValidationState"
                    >
                      <label for="">
                        License Expiry
                        <span class="text-danger"> *</span></label
                      >
                      <!-- <b-form-datepicker @input="checkLicenceFormValidity" v-model="driving.license_validation" required
                              class="data-text bg-light" /> -->
                      <flat-pickr
                        @input="checkLicenceFormValidity"
                        :state="licenceValidationState"
                        v-model="driving.license_validation"
                        required
                        placeholder="License Expiary Date"
                        class="data-text bg-light flat-pickar"
                        :config="{
                          altInput: true,
                          altFormat: 'F j, Y',
                          dateFormat: 'Y-m-d',
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group
                      label="Upload Licence"
                      label-for="Upload-Licence"
                    >
                      <label
                        for="Upload-Licence"
                        class="bg-light custom-file-upload d-flex justify-content-between align-items-center"
                      >
                        <div class="d-flex align-items-center">
                          <img
                            src="@/assets/images/icons/paper-clip.png"
                            width="20"
                            alt=""
                          />
                          <span
                            v-if="driving.license_file"
                            class="pl-1 file-name"
                            >{{ driving.license_file }}</span
                          >
                          <span class="placeholder pl-1" v-else
                            >Please Upload Licence File</span
                          >
                          <b-form-file
                            id="Upload-Licence"
                            class="d-none"
                            @change="handleLicenseFile($event)"
                          />
                        </div>
                        <img
                          v-if="
                            driving.license_file &&
                            driving.license_file.includes('uploads/')
                          "
                          v-b-modal.modal-center
                          @click.prevent="getPreviewFile(driving.license_file)"
                          src="@/assets/images/icons/eye.png"
                          alt=""
                        />
                      </label>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="text-right">
                    <button
                      v-if="$route.params.id > 0 && $can('update', 'Employee')"
                      class="btn update-btn"
                      @click.prevent="handleLicence"
                    >
                      Update License
                    </button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-end">
              <b-col
                cols="12"
                md="10"
                v-for="(cer, index) in certificate"
                :key="index"
              >
                <b-row class="rounded-lg">
                  <b-col class="text-left py-1">
                    <h3>Certifications</h3>
                    <hr />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group
                      invalid-feedback="Certificate Name Is Requird"
                      :state="cer.certificate_name_state"
                      :label-for="'Name-of-Certification' + index"
                    >
                      <label for=""
                        >Name of Certification
                        <span class="text-danger">*</span></label
                      >
                      <b-form-input
                        :id="'Name-of-Certification' + index"
                        @input="checkCertificateFormValidity"
                        v-model="cer.certificate_name"
                        placeholder="Enter Your Certificate Name"
                        required
                        class="data-text bg-light"
                        :state="cer.certificate_name_state"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group
                      label="Issued Organization"
                      label-for="Issued Organization"
                    >
                      <b-form-input
                        v-model="cer.certificate_issuing_authority"
                        placeholder="Enter Your Certificate Issued Organization"
                        required
                        class="data-text bg-light"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group
                      invalid-feedback="Certificate number is requird"
                      :state="cer.certificate_number_state"
                      :label-for="'Certificate-Number' + index"
                    >
                      <label for=""
                        >Certificate Number
                        <span class="text-danger">*</span></label
                      >
                      <b-form-input
                        :id="'Certificate-Number' + index"
                        v-model="cer.certificate_number"
                        placeholder="Enter The Certificate Number"
                        class="data-text bg-light"
                        :state="cer.certificate_number_state"
                        @input="checkCertificateFormValidity"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group
                      label="Certification Issue Date"
                      label-for="Certification Issue Date"
                    >
                      <!-- <b-form-datepicker v-model="cer.certificate_issuing_date" required class="data-text bg-light" /> -->
                      <flat-pickr
                        v-model="cer.certificate_issuing_date"
                        required
                        placeholder="Certification Issue Date"
                        class="data-text bg-light flat-pickar"
                        :config="{
                          altInput: true,
                          altFormat: 'F j, Y',
                          dateFormat: 'Y-m-d',
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group
                      invalid-feedback="Certificate Expire Date Is Requird"
                      :state="cer.certificate_validation_state"
                      :label-for="'Certificate-Expiry-Date' + index"
                    >
                      <!-- <b-form-datepicker v-model="cer.certificate_issuing_date" required class="data-text bg-light" /> -->
                      <label for=""
                        >Certificate Expiry Date
                        <span class="text-danger">*</span></label
                      >
                      <flat-pickr
                        :id="'Certificate-Expiry-Date' + index"
                        v-model="cer.certificate_validation"
                        required
                        placeholder="Certificate Expiry Date"
                        class="data-text bg-light flat-pickar"
                        :state="cer.certificate_validation_state"
                        @input="checkCertificateFormValidity"
                        :config="{
                          altInput: true,
                          altFormat: 'F j, Y',
                          dateFormat: 'Y-m-d',
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group
                      label="Upload Certificate"
                      :label-for="'Upload-Certificate' + index"
                    >
                      <label
                        :for="'Upload-Certificate' + index"
                        class="bg-light custom-file-upload d-flex justify-content-between align-items-center"
                      >
                        <div class="d-flex align-items-center">
                          <img
                            src="@/assets/images/icons/paper-clip.png"
                            width="20"
                            alt=""
                          />
                          <span
                            v-if="cer.certificate_new_file"
                            class="pl-1 file-name"
                            >{{ cer.certificate_new_file }}</span
                          >
                          <span
                            v-else-if="cer.certificate_file"
                            class="pl-1 file-name"
                            >{{ cer.certificate_file }}</span
                          >
                          <span class="placeholder pl-1" v-else
                            >Please Certificate File</span
                          >
                          <b-form-file
                            :id="'Upload-Certificate' + index"
                            class="d-none"
                            @change="handleCertificateFile($event, index)"
                          />
                        </div>
                        <img
                          v-if="
                            cer.certificate_file &&
                            cer.certificate_file.includes('uploads/')
                          "
                          v-b-modal.modal-center
                          @click.prevent="getPreviewFile(cer.certificate_file)"
                          src="@/assets/images/icons/eye.png"
                          alt=""
                        />
                      </label>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="d-flex justify-content-between">
                    <div v-if="$route.params.id > 0">
                      <button
                        class="btn bg-white shadow-lg mr-1"
                        @click.prevent="addMore"
                        v-if="index == certificate.length - 1"
                      >
                        <p class="m-0 add-btn-txt">
                          Add Certification
                          <span
                            ><img
                              src="@/assets/images/icons/add-icon.png"
                              alt=""
                          /></span>
                        </p>
                      </button>
                      <b-button
                        title="Remove"
                        class="btn btn-danger shadow-lg btn-sm"
                        @click.prevent="remove(index, cer)"
                        v-if="certificate.length > 1"
                      >
                        X
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div class="text-right">
              <button
                v-if="$route.params.id > 0 && $can('update', 'Employee')"
                class="btn update-btn"
                @click.prevent="updateCertificate"
              >
                Update Certificate
              </button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
    <b-col v-else class="card text-center py-4">
      <p class="text-warning">please insert previous data</p>
    </b-col>
    <b-modal hide-footer id="modal-center" centered>
      <div class="text-center p-2">
        <iframe :src="previewFile" height="400px" width="400px" alt="" />
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import { toast } from "@/utils/toast";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSelect,
  BFormTextarea,
  BRow,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@validations";
export default {
  props: {
    passport: {
      type: Object,
      default: {},
    },
    visaInfo: {
      type: Object,
      default: () => {},
    },
    tax: {
      type: Object,
      default: () => {},
    },
    driving: {
      type: Object,
      default: {},
    },
    certificate: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      default: 0,
    },
    residentType: {
      type: Number,
    },
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BCard,
    BFormTextarea,
    BFormFile,
    BFormDatepicker,
    BFormRadio,
    flatPickr,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      visaResidentValidationState: null,
      visaCardValidationState: null,
      visaExpiryValidationState: null,
      visaGrantNumberValidationState: null,
      passportState: null,
      passportValidationState: null,
      licenceState: null,
      licenceValidationState: null,
      TFN_Number_Validation: null,
      TFN_Exemption_Validation: null,
      formData: {},
      file: "",
      certificateFiles: [],
      countries: [],
      SelectedCountry: null,
      SelectedVisa: null,
      SelectedVisaSubClass: null,
      visa: [],
      visaSubClass: [],
      previewFile: null,
      email,
      required,
      exemption: [
        { name: "Pensioner" },
        // { name: "Under 18" },
        { name: "TFN not quoted" },
        { name: "Awaiting TFN" },
      ],
      taxScale: [
        { name: "Regular" },
        { name: "Horticulturist or shearers" },
        { name: "Actors, Variety artists and other entertainers" },
        { name: "Seniors or pensioners" },
      ],
    };
  },
  mounted() {
    this.getCountries();
    setTimeout(() => {
      if (this.visaInfo?.visa_type !== null) {
        this.SelectedVisa = this.visa.find(
          (visa) => visa.id == this.visaInfo?.visa_type
        );
      }
      if (
        this.visaInfo?.sub_class !== null &&
        this.visaInfo?.sub_class !== ""
      ) {
        this.getSelectedVisaInfo();
      }
    }, 2000);
  },
  created() {
    this.getVisa();
  },
  methods: {
    clearNumberValue() {
      if (this.tax.tfn_exemption !== "") {
        this.tax.tfn_number = "";
      }
      if (this.tax.tfn_exemption === "TFN not quoted") {
        this.tax.tax_scale_type = "";
      }
    },
    clearExemptionValue() {
      if (this.tax.tfn_number !== "") {
        this.tax.tfn_exemption = "";
      }
    },
    getPreviewFile(file) {
      this.previewFile = this.$store.state.appConfig.imageBaseUrl + file;
    },
    getSelectedVisaInfo() {
      this.$store
        .dispatch("employee/getVisaSubClass", this.SelectedVisa.id)
        .then((res) => {
          this.visaSubClass = res.data;

          this.SelectedVisaSubClass = this.visaSubClass.find(
            (visa) => visa.id == this.visaInfo.sub_class
          );
        })
        .catch(() => {
          // toast("Error", "AlertTriangleIcon", "danger", "Error Updating");
        });
    },
    getCountries() {
      this.query = {
        page: 1,
        limit: 300,
      };
      this.$store
        .dispatch("country/getCountries", this.query)
        .then((response) => {
          this.countries = response.data.data;
          if (this.countries.length > 0) {
            this.SelectedCountry = this.countries.find(
              (c) => c.id == this.passport.passport_issue_country
            );
          }
        });
    },
    handlePassport() {
      this.$refs.passportRules.validate().then((success) => {
        if (success) {
          if (this.SelectedCountry) {
            this.passport.passport_issue_country = this.SelectedCountry.id;
          }
          this.updatePassport();
        } else {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Please fill all the required fields"
          );
        }
      });
    },

    handleLicence() {
      // Exit when the form isn't valid
      if (!this.checkLicenceFormValidity()) {
        return;
      } else {
        this.updateLicence();
      }
    },
    checkLicenceFormValidity() {
      let valid = this.$refs.form.checkValidity();
      if (this.driving.license_number) {
        valid = true;
        this.licenceState = valid;
      } else {
        valid = false;
        this.licenceState = valid;
      }
      if (this.driving.license_validation) {
        valid = true;
        this.licenceValidationState = valid;
      } else {
        valid = false;
        this.licenceValidationState = valid;
      }
      return valid;
    },
    addMore() {
      this.certificate.push({
        certificate_file: "",
        certificate_name: "",
        certificate_validation: "",
        certificate_issuing_authority: "",
        certificate_issuing_date: "",
        certificate_number: "",
        certificate_number_state: null,
        certificate_name_state: null,
        certificate_validation_state: null,
      });
    },
    remove(index, cer) {
      if (this.certificate.length > 1) {
        if (cer.id) {
          this.deleteCertificate(cer);
        }
        this.certificate.splice(index, 1);
      }
    },
    deleteCertificate(cer) {
      let data = {
        certificate_id: cer.id,
        employee_id: this.id,
        type: "certificate",
      };

      this.$store
        .dispatch("employee/deleteEmployeeDocument", data)
        .then((res) => {
          if (res.status === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Certificate Deleted Successfully"
            );
          } else {
            toast("Error", "AlertTriangleIcon", "danger", "Error Deleting");
          }
        })
        .catch((error) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            error.response?.data?.message || "Error Deleting"
          );
        });
    },
    updatePassport() {
      if (this.SelectedCountry) {
        this.passport.passport_issue_country = this.SelectedCountry.id;
      }
      let formData = new FormData();
      formData.append("passport_file", this.file);
      formData.append(
        "passport_issue_country",
        this.passport.passport_issue_country
      );
      formData.append("passport_number", this.passport.passport_number);
      formData.append("passport_validation", this.passport.passport_validation);
      formData.append("id", this.id);
      this.$store
        .dispatch("employee/updatePassportInfo", formData)
        .then((res) => {
          if (res.status === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Passport Update Successfully"
            );
          } else {
            toast("Error", "AlertTriangleIcon", "danger", "Error Updating");
          }
        })
        .catch(() => {
          toast("Error", "AlertTriangleIcon", "danger", "Error Updating");
        });
    },
    updateLicence() {
      let licenceFormData = new FormData();
      licenceFormData.append("license_file", this.licenseFile);
      licenceFormData.append("issuing_state", this.driving.issuing_state);
      licenceFormData.append("license_number", this.driving.license_number);
      licenceFormData.append(
        "license_validation",
        this.driving.license_validation
      );
      licenceFormData.append("id", this.id);

      this.$store
        .dispatch("employee/updateDrivingLicenseInfo", licenceFormData)
        .then((res) => {
          if (res.status === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Driving License Update Successfully"
            );
          } else {
            toast("Error", "AlertTriangleIcon", "danger", "Error Updating");
          }
        })
        .catch(() => {
          toast("Error", "AlertTriangleIcon", "danger", "Error Updating");
        });
    },
    checkCertificateFormValidity(index) {
      let valid = this.$refs.form.checkValidity();
      this.certificate.forEach((element) => {
        if (element.certificate_name) {
          valid = true;
          element.certificate_name_state = valid;
        } else {
          valid = false;
          element.certificate_name_state = valid;
        }
        if (element.certificate_number) {
          valid = true;
          element.certificate_number_state = valid;
        } else {
          valid = false;
          element.certificate_number_state = valid;
        }
        if (element.certificate_validation) {
          valid = true;
          element.certificate_validation_state = valid;
        } else {
          valid = false;
          element.certificate_validation_state = valid;
        }
      });
      return valid;
    },
    updateCertificate() {
      if (!this.checkCertificateFormValidity()) {
        toast(
          "Error",
          "AlertTriangleIcon",
          "danger",
          "Please Fill All Fields."
        );
        return false;
      } else {
        this.$store
          .dispatch("employee/updateCertificationInfo", {
            id: this.id,
            certificate: this.certificate,
          })
          .then((res) => {
            if (res.status === 200) {
              this.$emit("getEmployee", this.$route.params.id);
              toast(
                "Success",
                "CheckCircleIcon",
                "success",
                "Certificate Update Successfully"
              );
            } else {
              toast("Error", "AlertTriangleIcon", "danger", "Error Updating");
            }
          })
          .catch(() => {
            toast("Error", "AlertTriangleIcon", "danger", "Error Updating");
          });
      }
    },
    handlePassportFile(e) {
      this.file = e.target.files[0];
      this.passport.passport_file = e.target.files[0].name;
    },
    handleLicenseFile(e) {
      this.licenseFile = e.target.files[0];
      this.driving.license_file = e.target.files[0].name;
    },
    handleCertificateFile(e, index) {
      let file = e.target.files[0];
      this.certificate[index].certificate_new_file = e.target.files[0].name;

      // make base64
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.certificate[index].certificate_file = reader.result;
      };
    },
    // this is visa section
    // this is visa list fetch function
    handleVisa() {
      this.$refs.visaRules.validate().then((success) => {
        if (success) {
          this.updateVisa();
        } else {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Please fill all the required fields"
          );
        }
      }).catch(() => {
        toast(
          "Validation Error",
          "AlertTriangleIcon",
          "danger",
          "Please fill all the required fields"
        );
      });
    },
    updateVisa() {
      if (this.SelectedVisa) {
        this.visaInfo.visa_type = this.SelectedVisa.id;
      }
      if (this.SelectedVisaSubClass) {
        this.visaInfo.sub_class = this.SelectedVisaSubClass.id;
      }
      if (this.visaInfo.id) {
        this.visaInfo.employee_id = this.id;
      }
      this.$store
        .dispatch("employee/updateVisaInfo", this.visaInfo)
        .then((res) => {
          if (res.status === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Visa Updated Successfully"
            );
          } else {
            toast("Error", "AlertTriangleIcon", "danger", "Error Updating");
          }
        })
        .catch(() => {
          toast("Error", "AlertTriangleIcon", "danger", "Error Updating");
        });
    },
    // update employee tax information
    updateTaxInformation() {
      let valid = this.$refs.form.checkValidity();
      // TFN_Number_Validation:null,
      // TFN_Exemption_Validation:null,
      if (this.tax.tfn_number || this.tax.tfn_exemption) {
        valid = true;
        this.TFN_Number_Validation = valid;
        this.TFN_Exemption_Validation = valid;
      } else {
        valid = false;
        this.TFN_Number_Validation = valid;
        this.TFN_Exemption_Validation = valid;
      }
      this.tax.resident_status = this.residentType;
      this.tax.id = this.id;
      if (this.tax.free_tax_threshold !== "1") {
        this.tax.free_tax_threshold = "0";
      }
      if (this.tax.tfn_exemption !== "" && this.tax.tfn_number === "") {
        this.tax.tfn_number = "";
      }
      this.$store
        .dispatch("employee/updateTaxInfo", this.tax)
        .then((res) => {
          if (res.status === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "TFN Updated Successfully"
            );
          } else {
            toast("Error", "AlertTriangleIcon", "danger", "Error Updating");
          }
        })
        .catch(() => {
          toast("Error", "AlertTriangleIcon", "danger", "Error Updating");
        });
    },
    getVisa() {
      this.$store
        .dispatch("employee/getVisa")
        .then((res) => {
          this.visa = res.data;
        })
        .catch(() => {
          // toast("Error", "AlertTriangleIcon", "danger", "Error Updating");
        });
    },
  },
};
</script>

<style scoped>
.update-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
}

.add-btn-txt {
  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.keen-text {
  color: #7190ef;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 27px;
}

.data-text {
  /* 18 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #01185e !important;
}

/*=================================================================*/

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.file > input[type="file"] {
  display: none;
}

.file > label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    color: hsl(48, 100%, 67%);
  }

  50% {
    color: hsl(48, 100%, 38%);
  }

  100% {
    color: hsl(48, 100%, 67%);
  }
}

.hide {
  display: none;
  position: absolute;
  margin-top: -100px;
}

.myDIV:hover + .hide {
  display: block;
  color: red;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.custom-text {
  padding: 10px;
  border-radius: 5px;
}
label.custom-file-upload {
  padding: 5px;
  border-radius: 5px;
  width: 100% !important;
  padding-left: 10px;
}
.placeholder {
  color: #c2c2cb !important;
}
.file-name {
  font-size: 12px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
